<template>
  <!-- 菜单管理页面 -->
	<div class="box">
		<div>
			<!-- 顶部 -->
			<div class="header">
				<div class="title">订单列表</div>
				<div class="content">
					<a-form layout="inline" :model="formState">
						<a-form-item label="手机号码">
							<a-input v-model="formState.phone" placeholder="请输入手机号码" style="width: 248px" />
						</a-form-item>
						<a-form-item label="商品名称">
							<a-input v-model="formState.businessName" placeholder="请输入商品名称" style="width: 248px" />
						</a-form-item>
						<a-form-item label="订单状态">
							<a-select ref="select" allowClear v-model:value="formState.status" placeholder="请选择订单状态" style="width: 248px">
								<a-select-option :value="0">全部</a-select-option>
                                <a-select-option :value="1">已支付</a-select-option>
                                <a-select-option :value="2">已退款</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="下单时间">
							<a-range-picker v-model="formState.termOfValidity" format="YYYY-MM-DD" @change="termChange" style="width:248px"/>
						</a-form-item>
                        <a-form-item>
							<a-button type="primary" html-type="submit" @click="searchClick">搜索</a-button>
						</a-form-item>
					</a-form>
				</div>
			</div>
			<div class="tableStyle">
                <a-table :columns="columns" :row-key="record => record.id" :data-source="tabledData" :pagination="false" :loading="tableLoading" bordered>
                    <span slot="orderStatus" slot-scope="orderStatus, record">
						<a-badge v-if="record.orderStatus == 0" color="#F56C6C"/>
						<a-badge v-else-if="record.orderStatus == 1" color="#67C23A"/>
						<span>{{ record.orderStatus == 1 ? '已支付' : '已退款' }}</span>
					</span>
				</a-table>
				<div class="page">
					<MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFns" v-show="tabledData.length > 0"/>
				</div>
			</div>
		</div>
		<router-view />
	</div>
</template>

<script>
import city from "@/utils/city.json";
import MyPagination from "@/components/pagination/MyPagination";
import { SomeonOrders } from "@/request/api/orderManage";
import { format } from "@/utils/tools";
export default {
    components: { MyPagination },
    created () {
        this.orderListFn();
	},
	watch: {},
    data () {
        return {
            pageNo: 1,
            count: 0,

            formState: {
                phone: "",
                businessName: "",
                status: undefined,
                termOfValidity:[],
            },
            tableLoading: true, // 表格的loading图
            isDisableSearch: false, // 搜索按钮是否禁用
            columns: [
                {
                    title: "用户昵称",
                    dataIndex: "nickname",
                    key: "nickname",
                },
                {
                    title: "手机号",
                    dataIndex: "phone",
                    key: "phone",
                },
                {
                    title: "订单类型",
                    dataIndex: "businessType",
                    key: "businessType",
                },
                {
                    title: "商品名称",
                    dataIndex: "businessName",
                    key: "businessName",
                },
                {
                    title: "订单金额",
                    dataIndex: "orderPrice",
                    align: 'right',
                    key: "orderPrice",
                },
                {
                    title: "订单状态",
                    dataIndex: "orderStatus",
                    key: "orderStatus",
                    scopedSlots: { customRender: "orderStatus" },
                },
                {
                    title: "收益",
                    dataIndex: "income",
                    align: 'right',
                    key: "income",
                },
                {
                    title: "下单时间",
                    dataIndex: "payTime",
                    key: "payTime",
                },
                {
                    title: "用户来源",
                    dataIndex: "registerChannel",
                    key: "registerChannel",
                },
                {
                    title: "订单来源",
                    dataIndex: "orderChannel",
                    key: "orderChannel",
                },
            ],
            tabledData: [],

            // 开始时间、结束时间
            starTime: '',
            endTime: '',
        }
	},
    methods: {
        showSizeChangeFns(current, pageSize) {
			this.pageNo = current;
			this.pageSize = pageSize;
			this.orderListFn();
        },

		// 搜索按钮 , 回车搜索
        searchClick () {
            this.tabledData = []
			this.isDisableSearch = true;
			setTimeout(() => {
			this.isDisableSearch = false;
			}, 3000);
			this.orderListFn();
        },

        // 有效期监听
        termChange (val) {
            if (val.length == 0) {
                this.starTime = ''
                this.endTime = ''
            } else {
                this.starTime = format(new Date(val[0]._d), "yyyy-MM-dd" +' '+'00:00:00');
                this.endTime = format(new Date(val[1]._d), "yyyy-MM-dd" +' '+'00:00:00');
            }
        },

		// 请求列表数据
        orderListFn () {
            let id = this.$route.params.id;
            SomeonOrders({
                page: this.pageNo,
                agentNo:id,
                phone:this.formState.phone,
                businessName:this.formState.businessName,
                orderStatus:this.formState.status,
                startTime:this.starTime,
                endTime:this.endTime
            }).then(({ code, data }) => {
                if (code === 200) {
                    this.tableLoading = false;
                    this.tabledData = data.list;
                    this.count = data.count
                } else {
                    this.tableLoading = false;
                }
			});
        },
	},
};
</script>

<style lang="less" scoped>
.box {
  min-height: 100%;
  // background-color: #fff;
  padding-bottom: 56px;
  .header{
    width: 100%;
    background-color: #fff;
	margin-bottom: 16px;
    .title{
      padding: 14px 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);;
    }
	.content{
		padding: 14px 16px;
	}
  }
  .tableStyle{
	background-color: #fff;
	padding: 16px;
	.page{
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
	}
  }
}
</style>
